import KeenSlider from 'keen-slider';

/**
 * @file Contains the frontend logic for the webcam viewer.
 * @author Justin Toffel
 * @author Martin Ziegler
 */

interface ResponseType {
    files: { [key: string]: number },
    next: string
}

// TypeScript definition for the expected response data
interface ResponseType {
    files: { [key: string]: number }; // Assuming key is a timestamp and value is the image path
}

export default function invokeWebcamViewer() {

    if(!document.querySelector(".webcam-wrapper")) return;

    let slider: any;
    let thumbnailSlider: any;
    // Function to fetch webcam data based on the selected day
    async function fetchWebcamData(selectedDay?: string): Promise<ResponseType | null> {
        try {
            // @ts-ignore
            const response = await fetch(`${import.meta.env.VITE_WEBCAM_BASE_URL}/stack/${selectedDay ?? ""}`);
            if (!response.ok) throw new Error("Network response was not ok");
            return await response.json();
        } catch (error) {
            console.error("Failed to fetch webcam data:", error);
            return null;
        }
    }
    
    // Function to initialize the sliders with the fetched data
    function initializeSliders(data: ResponseType) {
        const mainSliderContainer: HTMLElement | null = document.querySelector('#my-keen-slider');
        const thumbnailContainer: HTMLElement | null = document.querySelector('#thumbnails');
    
        if (!mainSliderContainer || !thumbnailContainer) {
            console.error("Slider containers not found");
            return;
        }
    
        // Clear existing slides
        mainSliderContainer.innerHTML = '';
        thumbnailContainer.innerHTML = '';
    
        // Populate the sliders
        for (const [key, value] of Object.entries(data.files).reverse()) {
    
            const time = new Date(parseInt(key));
            // const timestring = ("00" + time.getHours()).slice(-2) + ":00";

            // Create a new slide element for the main slider
            const mainSlide = document.createElement('div');
            mainSlide.classList.add('keen-slider__slide');
            mainSlide.innerHTML = `<img src="https://www.tushs.de/webcam/${value}" alt="Image ${value}" />`;
            mainSliderContainer.appendChild(mainSlide);
    
            // Create a new slide element for the thumbnail slider
            const thumbnailSlide = document.createElement('div');
            thumbnailSlide.classList.add('keen-slider__slide');
            thumbnailSlide.innerHTML = `
                <div>
                    <div>
                        <img src="https://www.tushs.de/webcam/${value}" alt="Thumbnail ${value}" />
                    </div>
                    <p>${time.toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit', hour12: false })} Uhr</span>
                </div>
            `;
            thumbnailContainer.appendChild(thumbnailSlide);
        }
    
        slider = new KeenSlider(mainSliderContainer, {
            slides: {
                perView: 1,
                spacing: 10,
            },
        });
    
        thumbnailSlider = new KeenSlider(
            thumbnailContainer,
            {
                initial: 0,
                slides: {
                    perView: 2.5,
                    spacing: 10,
                },
                breakpoints: {
                    '(min-width: 554px)': {
                        slides: {
                            perView: 4.5,
                            spacing: 10,
                        },
                    },
                    '(min-width: 992px)': {
                        slides: {
                            perView: 6,
                            spacing: 10,
                        },
                    },
                },
            },
            [createThumbnailPlugin(slider)]
        );

        slider.moveToIdx(slider.slides.length - 1);
        console.log(slider.slides.length)
    }
    
    function createThumbnailPlugin(mainSlider) {
        return (thumbnailSlider) => {
            function removeActive() {
                thumbnailSlider.slides.forEach((slide) => {
                    slide.classList.remove("active");
                });
            }
    
            function addActive(idx) {
                thumbnailSlider.slides[idx].classList.add("active");
            }
    
            function addClickEvents() {
                thumbnailSlider.slides.forEach((slide, idx) => {
                    slide.addEventListener("click", () => {
                        mainSlider.moveToIdx(idx);
                    });
                });
            }
    
            thumbnailSlider.on("created", () => {
                addActive(thumbnailSlider.track.details.rel);
                addClickEvents();
    
                mainSlider.on("animationStarted", (main) => {
                    removeActive();
                    const next = main.animator.targetIdx || 0;
                    addActive(main.track.absToRel(next));
                    thumbnailSlider.moveToIdx(Math.min(thumbnailSlider.track.details.maxIdx, next));
                });
            });
        };
    }

    // Call this function initially with a default day
    async function loadInitialData() {
        const data = await fetchWebcamData();
        if (data) {
            initializeSliders(data);
        }
    }

    // Load the initial data on invocation
    loadInitialData();

    const webcamTimeline = document.querySelector<HTMLElement>("#webcam-timeline");
    // Handle click outside of days dropdown
    const handleClickOutside = (event: Event) => {
        if (webcamTimeline.dataset.open === "true" && !webcamTimeline.contains(event.target as Node)) {
            webcamTimeline.dataset.open = "false";
        }
    };

    // days dropdown label click handler
    document.querySelector(".timeline-point-label").addEventListener("click", () => {
        if (webcamTimeline) {
            if(webcamTimeline.dataset.open === "true") {
                webcamTimeline.dataset.open = "false";
            } else {
                webcamTimeline.dataset.open = "true";
                document.addEventListener("click", handleClickOutside, true);
            }
        }
    });

    function formatDateToYYYYMMDD(date: any) {
        const year = date.getFullYear(); // Get the full year (YYYY)
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Get the month (MM)
        const day = String(date.getDate()).padStart(2, '0'); // Get the day (DD)
        return `${year}${month}${day}`; // Return formatted date
    }
    
    function getTimelinePointLabel(index: number) {
        switch(index) {
            case 0: return "Heute"
            case 1: return "Gestern"
            case 2: return "Vorgestern"
            case 3: return "Vor 3 Tagen"
            case 4: return "Vor 4 Tagen"
            case 5: return "Vor 5 Tagen"
        }
    }

    document.querySelectorAll('.timeline-point').forEach((day) => {
        day.addEventListener('click', async (event) => {
            const selectedIndex = parseInt((event.target as HTMLElement).dataset.date);
    
            // Calculate the date for the selected index
            const today = new Date(); // Get today's date
            const selectedDate = new Date(today); // Create a new date object for the selected date
            selectedDate.setDate(today.getDate() - selectedIndex); // Subtract the number of days
    
            // Format the date to YYYYMMDD
            const formattedDate = formatDateToYYYYMMDD(selectedDate);
            document.querySelector<HTMLElement>(".timeline-point-label span").innerText = getTimelinePointLabel(selectedIndex);
    
            // Fetch webcam data with the formatted date
            const data = await fetchWebcamData(formattedDate);
            if (data) {
                webcamTimeline.dataset.open = "false";
                initializeSliders(data);
            }
        });
    });

    document.querySelector("#prev-slide").addEventListener("click", () => {
        slider.next();
        thumbnailSlider.next();
    });

    document.querySelector("#next-slide").addEventListener("click", () => {
        slider.prev();
        thumbnailSlider.prev();
    });

    document.querySelector("#webcam-open").addEventListener("click", () => {
        document.body.classList.add("noscroll");
        document.querySelector<HTMLElement>(".webcam-wrapper").style.display = "flex";
        slider.update();
        thumbnailSlider.update();
    });

    document.querySelector("#webcam-close").addEventListener("click", () => {
        document.body.classList.remove("noscroll");
        document.querySelector<HTMLElement>(".webcam-wrapper").style.display = "none";
    });
    
    const webcampWrapper = document.querySelector<HTMLElement>(".webcam-wrapper");

    const escapeFullScreenListener = (event: KeyboardEvent) => {
        if (event.key === "Escape") {
            webcampWrapper.dataset.fullscreen = "false";
        }
    };
    
    document.querySelector("#webcam-fullscreen").addEventListener("click", () => {
        if (webcampWrapper.dataset.fullscreen === "true") {
            webcampWrapper.dataset.fullscreen = "false"
            document.removeEventListener("keydown", escapeFullScreenListener);
        } else {
            webcampWrapper.dataset.fullscreen = "true";
            document.addEventListener("keydown", escapeFullScreenListener);
        }
        slider.update();
        thumbnailSlider.update();
    });
}
